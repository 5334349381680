<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">{{ $t('access_denied.title') }} 🔐</h2>
        <p class="mb-2">
          {{ $t('access_denied.message') }}
        </p>
        <b-button class="mb-1 btn-sm-block" :to="{ name: 'home' }">{{ $t('access_denied.return_home') }}</b-button>
        <b-img fluid :src="imgUrl" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotAuthorized',

  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
